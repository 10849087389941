import React, { useState, useEffect } from 'react';
import { Button, Modal, ButtonGroup, ButtonToolbar, Row, Col } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';

import utils from "../../../utils"
import { useToast } from '../../ToastProvider'
import '../../../styles/layout/_layout.scss'
import GroupEditor from './GroupEditor';
import checkForErrors from './checkForErrors';

function AppEditor(props) {

  /*--------------------------State variables---------------------------*/
  const [app, setApp] = useState(null)
  const [sorted, setSorted] = useState(false);
  const [errors, setErrors] = useState([]);
  const { addToast } = useToast();

  useEffect(() => {
    if (!app||sorted) {
      return;
    }
    const temp = [...app.appFields]
    temp.sort((a, b) => a.groupTitle.localeCompare(b.groupTitle))
    setApp({ ...app, appFields: temp })
    setSorted(true);
    
    // React 18 ready
    return () => { };
  }, [app, sorted])

  useEffect(() => {
    if (props.getFromLocalStore === true) {
      fetchFromLocalStore();
    } else {
      fetchData();
    }
    return () => { };
    // eslint-disable-next-line
  }, [props.apiKey, props.id, addToast])

  async function fetchData() {
    if (props.id) {
      let res = await utils.getData('get_app', props.apiKey, "?app_id=" + props.id);
      if (typeof res === 'string') {
        addToast({
          title: 'Failed to get vaccine. Error code 5-a',
          body: res
        })
        props.closePopup();
      } else {
        if (res) {
          setApp(res);
        }
      }
    } else {
      setApp({appFields:[]})
    }
  }

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const errors= checkForErrors(app);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const result = await utils.db_api_post('upsert_app', props.apiKey, app);
    if (result !== null) {
      if (props.onSubmit) {
        props.onSubmit(result);
      }
      if (result.error) {
        sendToLocalStorage();
        addToast({
          title: 'Failed to submit app. Error code 5-c',
          body: result.error,
          onClick: props.toastClick,
          buttonText: "Reopen App?"
        })
      }
      props.closePopup()
    }
  }


  function fetchFromLocalStore() {
    const obj = localStorage.getItem('errorApp');
    const objJSON = JSON.parse(obj);
    setApp(objJSON.app);
  }

  function sendToLocalStorage() {
    localStorage.setItem('errorApp', JSON.stringify({ app: app }));
  }

  function editGroup(group, index) {
    let newApp = { ...app }
    newApp.appFields[index] = group
    setApp(newApp)
  }

  function addGroup() {
    let newApp = { ...app }
    newApp.appFields.push({ groupTitle: '', show: true, subNav: [] })
    setApp(newApp)
  } 

  function deleteGroup(index){
    let newApp = { ...app }
    newApp.appFields.splice(index, 1)
    setApp(newApp)
  }

  function toSnakeCase(str) {
    return str &&
      str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('_');
  }

  function setAppType(e) {
    setApp({ ...app, appType: e.target.value })
    if (!app.app_id){
      setApp({ ...app, appType: e.target.value, key: toSnakeCase(e.target.value) })
    }
  }
  return (
    <div>
      {
        app ?
          <Modal show={true} dialogClassName='modal-90w' className={'alc-app'}>
            <Modal.Header>
              <Modal.Title>App Editor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body>
                  <h3>App Name</h3>
                  <Row>
                    <Col>
                      <div className='input-text'>App Type</div>
                      <input
                        className='form-ctrl'
                        type='text'
                        required
                        value={app.appType}
                        onChange={setAppType}
                      />
                    </Col>
                    <Col>
                      <div className='input-text'>App Key</div>
                      <input
                        className='form-ctrl'
                        type='text'
                        required
                        value={app.key}
                        disabled
                      />
                    </Col>
                  </Row>
                  
                </Card.Body>
              </Card>
              
              <Card className='nav-card-with-margin'>
                <Card.Body>
                  <h3>{`Tab level pages within ${app.appType}`}</h3>
                  <div className='input-text'>A group is functionally a page, or tab, that shows up as tabs/news pages within an app</div>
                  <Row>
                    <Col xs={8}>
                      <div className='input-text'>Group Name</div>

                    </Col>
                    <Col xs={1}>
                      <div className='input-text'>Show?</div>

                    </Col>
                    <Col xs={1}>
                    </Col>
                    <Col xs={5}> </Col>
                  </Row>
                  {
                    app.appFields.map((group, index) => {
                      return <GroupEditor
                        key={index}
                        group={group}
                        onChange={(group)=>{
                          editGroup(group, index)
                        }
                        }
                        onDelete={()=>deleteGroup(index)}
                      />
                    })
                  }
                  <Row>
                    <Col xs={10}/>
                    <Col>
                      <button
                        className="btn-sm-invisible-dark"
                        onClick={addGroup}
                        aria-controls="example-collapse-text"
                      >
                        <PlusCircle/>{' '}Add Group
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              
              

              <ButtonToolbar className='editor-btn'>
                <ButtonGroup>
                  <Button className='button-secondary m-i-end-02' onClick={props.closePopup} variant="secondary">Close</Button>{' '}
                </ButtonGroup>
                <ButtonGroup>
                  <Button className='btn-primary m-i-end-02' onClick={handleSubmit} variant="primary">Submit</Button>{' '}
                </ButtonGroup>
                <ButtonGroup>
                  <Button onClick={() => console.log(app)} variant="primary">Check App</Button>
                </ButtonGroup>
              </ButtonToolbar>
              {errors.length === 0? null:
                <>
                  <div className = 'error'>Errors:</div>
                  {errors.map((e, index) => <li key={index} className="error">{e}</li>)}
                </>
              }
            </Modal.Body>
          </Modal>
          : null
      }
    </div>
  )
}

export default AppEditor;