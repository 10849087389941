
const tenantUtils = {

    getTenantInfo : async (accToken, tenantId, special=null) => {

        if(accToken && tenantId !== undefined) {
            let tidParam = '';
            let specObj = {};
            if(tenantId){
                tidParam = '?tenid='+tenantId;
            }

            if(special){
                specObj = { "x-special-pass": `${special}` }
            }

            let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/tenant-service-v2${tidParam}`, {
                headers: { 
                    "Authorization": "Bearer "+ accToken.accessToken,
                    ...specObj
                }
            });
            if (res.status !== 200){
                // Return status and status text as string.
                return res.status+"-"+res.statusText;
            }
            let jsonRes = await res.json();
            
            return jsonRes;
        }
    },

    isAdminMember : (accts) => {
        if(accts.length > 0){
            return accts[0].username.indexOf('@linksbridge.com') > -1 || accts[0].username.indexOf('@sassenach.co') > -1;
        }

        return false;
    }


}

export default tenantUtils;