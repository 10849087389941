import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { models } from "powerbi-client";
import { PowerBIEmbed } from 'powerbi-client-react';
import utils from '../utils';
import { useToast } from '../components/ToastProvider';


import '../styles/components/_pbi-report.scss';

function PBIEmbeddedViz(props) {

    let [ embedData, setEmbedData ] = useState({embedUrl: undefined, token: undefined});
    const { addToast } = useToast();

    let { instance, accounts } = useMsal();

    const loginRequest = {
        scopes: ["User.Read"]
    };

    //construct filter if exists on config
    // Our powerBI service expects the each powerBI served to have roles for each security level 1-Q. If getting an error makesure PBI that is being called has the role table in it.

    const getEmbedData = async () => {
        let tokenForUse = await utils.getAccessToken(instance, accounts[0], loginRequest);
        let resJSON;
        try {
            let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/bi-service?reportid=${props.reportID}&datasetid=${props.datasetID}&reportType=${props.reportType || 'report'}`,{
                method: 'GET',
                headers: { "Authorization": "Bearer "+ tokenForUse.accessToken }
            });
    
            resJSON = await res.json();
            
            setEmbedData(resJSON);
        } catch (err) {
            addToast({
                title: 'Failed to load PowerBI report. Contact admin if issue persists.',
                body: err,
              });
              return;
        }
    }

    useEffect( ()=> {
        getEmbedData();

        return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reportID])

    return (
            <div className="pbi-report">
                <PowerBIEmbed 
                    embedConfig = {{
                        type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                        id: props.reportID,
                        embedUrl: embedData.embedUrl,
                        accessToken: embedData.token,
                        
                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed - was models.TokenType.Embed
                        filters: props.filters? props.filters : [],
                        settings: {
                            zoomLevel: props.zoomLevel? props.zoomLevel : 1,
                            layoutType: props.layoutType ? props.layoutType : models.LayoutType.Custom,
                            customLayout: {
                                pageSize: {
                                    type: props.pageSizeType ? props.pageSizeType : models.PageSizeType.Custom,
                                    width: props.initWidth ? props.initWidth : '100%',
                                    height: props.initHeight ? props.initHeight : 800
                                }
                            },
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                },
                                pageNavigation: {
                                    visible: false
                                }
                            }
                        }
                        }}
                        cssClassName={"pbi-report"} />
            </div>
    )
}

export default PBIEmbeddedViz