import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import utils from "../../utils";
import ircUtils from "../IRC/ircUtils";
import constants from "../../constants";

import BreadCrumbBar from "../Layout/Nav/BreadCrumbBar";
import CENewsToElasticAdapter from "./CENewsToElasticAdapter";
import CEFilterBar from "./CEFilterBar";
import PublicationsList from "./PublicationsList";
import ListPagination from "../Layout/ListPagination";



function CENewsApp({ tenantSetting }) {
    const [diseaseList, setDiseaseList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [filters, setFilters] = useState([]);
    const [articles, setArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [loading, setLoading] = useState(false);

    const [curPage, setCurPage] = useState(1);
    const paginationsize = 25; 

    const history = useHistory();
    const { instance, accounts } = useMsal();

    const tenantID = utils.getForceTenantValue() || tenantSetting?.tenant?.fields?.aDGroupID;

    if (tenantID && !utils.getForceTenantValue()) {
        utils.setForceTenantValue(tenantID);
    }

    const getAPIKey = async () => {
        const token = await utils.getAccessToken(instance, accounts[0], { scopes: ["User.Read"] });
        const response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {
            headers: { Authorization: `Bearer ${token.accessToken}` },
        });
        const { value } = await response.json();
        return value;
    };

    const fetchAllArticles = async () => {
        try {
            const apiKey = await getAPIKey();
            const searchObj = {
                engine: "ce-articles",
                size: 10000, 
                page: 1,
                filters: {},
                sort_order: "DATE_DESC",
                tenant_id: tenantID,
            };

            const response = await utils.db_api_post("lb_search", apiKey, searchObj);

            if (response.results) {
                setArticles(response.results);
                setFilteredArticles(response.results); 
            } else {
                console.error("No articles found in response:", response);
            }
        } catch (error) {
            console.error("Error fetching articles:", error);
        }
    };


    // Country and Disease options for the filter bar
    const fetchCountriesAndDiseases = async () => {
        try {
            const apiKey = await getAPIKey();
            const [countries, diseases] = await Promise.all([
                utils.getData("get_ce_countries", apiKey, ""),
                utils.getData("get_ce_domains", apiKey, ""),
            ]);

            setCountryList(
                countries.filter((c) => c.trim() !== "").map((c) => ({ label: c, value: c }))
            );
            setDiseaseList(
                diseases.filter((d) => d.trim() !== "").map((d) => ({ label: d, value: d }))
            );
        } catch (error) {
            console.error("Error fetching countries or diseases:", error);
        }
    };


    // year options for the filter bar
    const extractUniqueYears = (articles) => {
        const uniqueYears = [
            ...new Set(articles.map((article) => article.fields?.year).filter(Boolean)),
        ]
            .sort((a, b) => b - a) // Sort years in descending order
            .map((year) => ({ label: year, value: year }));
        setYearList(uniqueYears);
    };


    useEffect(() => {
        const initializeArticles = async () => {
            setLoading(true);
            await fetchAllArticles();
            setLoading(false);
        };
        const initializeFilters = async () => {
            await fetchCountriesAndDiseases();
        };
        initializeArticles();
        initializeFilters();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    


    useEffect(() => {
        if (articles.length > 0) {
            extractUniqueYears(articles); // Ensure this is called after articles are fetched
        }
    }, [articles]);
    

    const filterChanged = (newFilter) => {
        setFilters((prevFilters) => {
            const updatedFilters = prevFilters.filter((f) => f.field !== newFilter.field);
            // Add the new filter if it has a value
            if (newFilter.value !== null && newFilter.value !== undefined) {
                updatedFilters.push(newFilter);
            }
            applyFilters(updatedFilters);
            updateQueryParams(updatedFilters);

            return updatedFilters;
        });
    };



    // Apply Filters with Tag priority
    const applyFilters = (updatedFilters) => {
        if (!articles || articles.length === 0) {
            console.warn("No articles available to filter");
            setFilteredArticles([]);
            return;
        }
    
        const selectedCountries = updatedFilters.find((f) => f.field === "country")?.value || [];
        const selectedDiseases = updatedFilters.find((f) => f.field === "disease")?.value || [];
        const selectedYears = updatedFilters.find((f) => f.field === "year")?.value || [];
        const integrationFilter = updatedFilters.find((f) => f.field === "integration");
    
        let filteredResults = articles;
    
        // Filter by countries (OR condition within countries)
        if (selectedCountries.length > 0) {
            filteredResults = filteredResults.filter((article) => {
                const articleCountries = article.fields?.countries || [];
                return selectedCountries.some((country) => articleCountries.includes(country));
            });
        }
    
        // Filter by diseases (OR condition within diseases)
        if (selectedDiseases.length > 0) {
            filteredResults = filteredResults.filter((article) => {
                const articleDiseases = article.fields?.diseases || [];
                return selectedDiseases.some((disease) => articleDiseases.includes(disease));
            });
        }
    
        // Filter by years (OR condition within years)
        if (selectedYears.length > 0) {
            filteredResults = filteredResults.filter((article) =>
                selectedYears.includes(article.fields.year)
            );
        }
    
        // Filter by integration
        if (integrationFilter) {
            filteredResults = filteredResults.filter((article) =>
                String(article.fields.integration).toLowerCase() === String(integrationFilter.value).toLowerCase()
            );
        }
    
        setFilteredArticles(filteredResults);
    };
    




    const updateQueryParams = (filters) => {
        const params = new URLSearchParams();

        filters.forEach((filter) => {
            if (Array.isArray(filter.value)) {
                filter.value.forEach((val) => params.append(filter.field, val));
            } else if (filter.value !== null && filter.value !== undefined) {
                params.append(filter.field, filter.value);
            } else {
                params.delete(filter.field);
            }
        });

        history.push({ search: params.toString() });
    };


    // handle Badge click - use badges, not TagDisplay
    const handleBadgeClick = (tag) => {
    
        // Determine if the tag matches a disease or country
        const isDisease = diseaseList.some((d) => d.value.toLowerCase() === tag.toLowerCase());
        const isCountry = countryList.some((c) => c.value.toLowerCase() === tag.toLowerCase());
    
        // Update filters based on the tag
        const updatedFilters = [];
        if (isDisease) {
            updatedFilters.push({ field: "disease", value: [tag] });
        }
        if (isCountry) {
            updatedFilters.push({ field: "country", value: [tag] });
        }
    
        // Update filters and URL
        setFilters(updatedFilters);
        const params = new URLSearchParams();
        params.set("tag", tag); 
        history.push({ search: params.toString() });
    
        // Filter all articles based on the tag
        const filteredResults = articles.filter((article) => {
            const matchesDisease = isDisease && article.fields.diseases.includes(tag);
            const matchesCountry = isCountry && article.fields.countries.includes(tag);
            return matchesDisease || matchesCountry;
        });
    
        // Update filtered articles and reset pagination
        setFilteredArticles(filteredResults);
        setCurPage(1); // Reset pagination to the first page
    };
    
    
    
    
    
    

// ---- pagination ---- //
    const handlePageChange = (pageNum) => {
        setCurPage(pageNum);
    };

    // Calculate paginated articles
    const startIdx = (curPage - 1) * paginationsize;
    const paginatedArticles = filteredArticles.slice(startIdx, startIdx + paginationsize);


    return (
        <>
            <BreadCrumbBar appName={constants.APPS_BY_APPTYPE.CE_NEWS} />
            <h1 className="h1 m-b-start-08 m-b-end-08">{constants.APPS_BY_NAME.CE_NEWS}</h1>
            <Tab.Container id="ce-news-tabs" defaultActiveKey="news">
                <Nav variant="tabs" className="mb-3">
                    <Nav.Item key="news">
                        <Nav.Link eventKey="news">News</Nav.Link>
                    </Nav.Item>
                    <Nav.Item key="publications">
                        <Nav.Link eventKey="publications">Publications</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="news">
                        <Row>
                        <Col lg={9} className="search-content">
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <CENewsToElasticAdapter
                                        loading={loading}
                                        articles={paginatedArticles}
                                        inputArray={filters}
                                        paginationsize={25}
                                        getNewsArticleTags={ircUtils.getNewsArticleTags}
                                        formatForNewsList={ircUtils.formatForNewsList}
                                        filterChanged={filterChanged}
                                        handleBadgeClick={handleBadgeClick}
                                    />
                                )}
                                {paginationsize > 0 && filteredArticles.length > paginationsize && (
                                    <ListPagination
                                        articlesPerPage={paginationsize}
                                        totalArticles={filteredArticles.length}
                                        paginate={handlePageChange}
                                        curPage={curPage}
                                    />
                                )}
                            </Col>
                            <Col lg={3} className="uni-filter-bar m-b-start">
                                <CEFilterBar
                                    onFilterChanged={filterChanged}
                                    countryList={countryList}
                                    diseaseList={diseaseList}
                                    yearList={yearList}
                                    curFilters={filters}
                                />
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="publications">
                        <Row>
                            <Col lg={9}>
                                <PublicationsList titleOfList="" listName="cepublicationlist" />
                            </Col>
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    );
}

export default CENewsApp;

