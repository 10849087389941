import CONSTANTS from "../../constants";
import { getCode, getName } from 'country-list';
import getCountryISO2 from "country-iso-3-to-2";


const ircUtils = {

    getNewsArticleTags: (thisItem) => {
        if (!thisItem?.fields?.tags || !Array.isArray(thisItem.fields.tags)) {
          return []; 
        }
      
        const filteredTags = thisItem.fields.tags
          .filter((tag) => typeof tag === "string" && tag.trim() !== "") // skips the one blank tag
          .map((tag) => tag.trim()); 
      
        return filteredTags;
      },

      // for IRC Country Profiles => News
      formatForElastic: (articles) => {
        return articles.map((thisArticle) => {
          const title = thisArticle.title?.raw || ''; 
          const body = thisArticle.body?.raw || '';
          const contentid = thisArticle.contentid?.raw || '';
          const datepublished = thisArticle.date_published?.raw || '';
          const integration = thisArticle.integration?.raw || '';
          const tags = ircUtils.getNewsArticleTags(thisArticle); 
      
          return {
            fields: {
              title,
              body,
              link: `/ce_news/${contentid}`, 
              datepublished,
              integration,
              tags,
            },
            contentID: contentid, 
          };
        });
      },

    // for CE News from lb_search, not going through BasicElasticEngine
    formatForNewsList: (articles) => {

        return articles.map((thisArticle) => {

            const fields = thisArticle.fields || {};
            const title = fields.title 
            const body = fields.body 
            const contentid = fields.contentid 
            const datepublished = fields.datepublished  || fields.date_published
            const integration = fields.integration
            const tags = ircUtils.getNewsArticleTags(thisArticle)

            return {
                fields: {
                    title,
                    body,
                    link: `/ce_news/${contentid}`,
                    datepublished,
                    integration,
                    tags,
                },
            };
        });
    },

    getThreeLetter: (ctryName, countriesList) => {
        let adjCtryName = ctryName;
    
        if( CONSTANTS.COUNTRY_ADAPTER.toCE.hasOwnProperty(ctryName) ) {
            adjCtryName = CONSTANTS.COUNTRY_ADAPTER.toCE[ctryName];
        }
    
        return countriesList.find( (itemInList) => {
            //add transform back to our three letters here, ie: DRC / CAF / Cote d'Ivoire
            
            return itemInList.country === adjCtryName;
        });
    },


    createJVector: (countryList) => {
        let retObj = {};
    
        countryList.forEach( (itemInList) => {
    
            let countryName = itemInList.country;
    
            //check our adapter to see if this is a mismatched case
            if(CONSTANTS.COUNTRY_ADAPTER.toJvector.hasOwnProperty(itemInList.country)){
                countryName = CONSTANTS.COUNTRY_ADAPTER.toJvector[itemInList.country];
            } 
    
           retObj[getCode(countryName)] = 1;
        })
    
        return retObj;
    },

    stripNewsArticles : (articleArray) => {
        const regex = /<(?!a|\/a|p|\/p).*?>/gi;

        articleArray.forEach((thisArticle) => {
            thisArticle.content.rendered.replace(regex, '');
        });

        return articleArray;
    },

    getNewsFromWP : async (iso) => {

        let TwodigitCountryISO = getCountryISO2(iso);
        let countryName = getName(TwodigitCountryISO);

        try {
            let resp = await fetch(`${process.env.REACT_APP_WEB_API}/api/newscontentbytag?newssource=CE&tag=${countryName}`,
                {
                    headers: {
                        "x-functions-key": process.env.REACT_APP_NEWS_CONTENT_TAG
                    }
                });

            let data = await resp.json();
            return ircUtils.stripNewsArticles(data);

        } catch (error) {
            console.error('Error fetching news articles: ', error);
            return [];
        }
    }

}

export default ircUtils;