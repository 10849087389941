import React from 'react';
import { Row, Card, Button, Image } from 'react-bootstrap';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import ToastProvider from '../components/ToastProvider';

import utils from '../utils';
import '../styles/main.scss';
import MainAppPage from '../pages/appPages/MainAppPage';
import SigninLink from '../components/SigninLink';
import NewsAppPage from '../pages/appPages/news/NewsAppPage';
import ArticleDisplay from '../components/News/ArticleDisplay';
import CoProfileMainPage from '../pages/companyProfiles/CoProfileMainPage';
import SearchResultsPage from '../pages/SearchResultsPage';
import IndustryAnalysisAppPage from '../pages/industryAnalysis/IndustryAnalysisAppPage';
import VaccineMarketsAppPage from '../pages/vaccineMarkets/VaccineMarketsAppPage';
import VaccineMarketsListPage from '../pages/vaccineMarkets/VaccineMarketsListPage';
import IntelligenceAppPage from '../pages/intelligence/IntelligenceAppPage';
import IntelligenceDisplay from '../components/Intelligence/IntelligenceDisplay';
import IntellEditReport from '../components/Intelligence/IntellEditReport';
import AlchemyAppPage from '../pages/Alchemy/AlchemyAppPage';
import microsoftLogo from '../assets/microsoft-logo.png'
import MarketActionsAppPage from '../pages/marketActions/MarketActionsAppPage';
import MarketActionsMarketPage from '../pages/marketActions/MarketActionsMarketPage';
import PMTAppPage from '../pages/PMT/PMTAppPage';
import AdrsPopEstAppPage from '../pages/AddrsPopEstimator/AdrsPopEstAppPage';
import MetaAppPage from '../pages/meta/MetaAppPage';
import PrtnrProfilesAppPage from '../pages/PrtnrProfiles/PrtnrProfilesAppPage';
import PrtnrProfilesListPage from '../pages/PrtnrProfiles/PrtnrProfilesListPage';
import SettingsPage from '../pages/SettingsPage';
import LogsAppPage from '../pages/LogsApp/LogsAppPage';
import EthiopiaCtryAppPage from '../pages/EthiopiaCountryApp/EthiopiaCtryAppPage';
import NigeriaCtryAppPage from '../pages/NigeriaCountryApp/NigeriaCtryAppPage';
import CONSTANTS from '../constants';
import TopicNodePage from '../components/News/TopicNodePage';
import Notification from '../components/Layout/Notification/Notification';
import SuperAdminMgmtPage from '../pages/LogsApp/SuperAdminMgmtPage';
import IRCAppPage from '../pages/IRCApp/IRCAppPage';
import CENewsAppPage from '../pages/CENews/CENewsAppPage';
import CEArticleDisplay from '../components/CENews/CEArticleDisplay';
import PMTCONSTANTS from '../components/PMT/pmtConstants';
import DemandHealthAppPage from '../pages/PMT/DemandHealthAppPage';
import CampaignHubAppPage from '../pages/CampaignHub/CampaignHubAppPage';
import ChineseMktAppPage from '../pages/ChineseMarket/ChineseMktAppPage';

function SignInPageRoutes({ tenantSettings, notifications, apiError, tooltip, showNav, windowSize, curNav, firstTimeUser, accessToken, isAuthenticated, queryRequest }) {
  



    const history = useHistory();


    let { path } = useRouteMatch();

        // browser tabs
    const getPathnameFormat = (name) => {
        let pathname = name.split('/');
        pathname = pathname[1].toUpperCase();
        if (pathname) {
            document.title = `Workspace | ${CONSTANTS.APPS_BY_NAME[pathname]}`;
        } else {
            document.title = `Workspace | Home`
        }
        return pathname;
    }

    let urlPathname = window.location.pathname;
    getPathnameFormat(urlPathname)

    const getTooltipInfo = (tooltipArr) => {
        let tooltipItem;

        tooltipArr.forEach((item) => {
            if (item.fields.key === "sensitivityTooltip") {
                tooltipItem = item.fields.content;
            }
            return tooltipItem;
        })
        return tooltipItem
    }

    const getTooltipContentByKey = (tooltipArr, key) => {
        const tooltipItem = tooltipArr?.find(item => item.fields.key === key);
        if (!tooltipItem) {
            return 'No tooltip available';  // Return plain text for missing keys
        }
        
        const content = tooltipItem.fields.content;
        
        // Check if the content contains HTML tags
        if (/<[a-z][\s\S]*>/i.test(content)) {
            return utils.getHTML(content);  // Wrap HTML string in { __html: content }
        }
        
        return content;  // Return plain text directly
        };

    return (
    <>
        {
            notifications && notifications.data && notifications.data.length > 0 ?
                notifications.data.map((note) => {
                    return (
                        <Notification notificationType={note.fields.notificationType} content={note.fields} />
                    )
                })
                : ''
        }
        <ToastProvider>
            <Row className="main-body-text">
                {
                    tenantSettings && accessToken && isAuthenticated ?
                        <div className='isAuth-container'>
                            <div className={showNav ? 'isAuth-landing-page show' : 'isAuth-landing-page hide'}>
                                <Switch>
                                    <Route exact path={`${path}`}>
                                        <MainAppPage
                                            tenantSettings={tenantSettings}
                                            token={accessToken}
                                            showNav={showNav}
                                            windowSize={windowSize}
                                            showFakes={firstTimeUser} />
                                    </Route>

                                    <Route exact path={`${path}settings`}>
                                        <SettingsPage tenant={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}logs`}>
                                        <LogsAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}super_admin`}>
                                        <SuperAdminMgmtPage tenant={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}news`}>
                                        <NewsAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}ce_news`}>
                                        <CENewsAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}ce_news/:articleID`}>
                                        <CEArticleDisplay wokrspace={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}topic/:topicSlug`}>
                                        <TopicNodePage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}news/:storyID`}>
                                        <ArticleDisplay tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}company_profiles/:slug?`}>
                                        <CoProfileMainPage tenant={tenantSettings} token={accessToken} singularCompany={false} />
                                    </Route>
                                    <Route path={`${path}my_company_profile`}>
                                        <CoProfileMainPage tenant={tenantSettings} token={accessToken} singularCompany={true} />
                                    </Route>
                                    <Route exact path={`${path}partner_profiles`}>
                                        <PrtnrProfilesListPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}partner_profiles/:slug`}>
                                        <PrtnrProfilesAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}search/:query`}>
                                        <SearchResultsPage tenant={tenantSettings} query={queryRequest} curNav={curNav} />
                                    </Route>
                                    <Route exact path={`${path}glbl_hlth_alchemy`}>
                                        <AlchemyAppPage tenant={tenantSettings} tooltip={tooltip} getTooltipContentByKey={getTooltipContentByKey} />
                                    </Route>
                                    <Route path={`${path}industry_analysis`}>
                                        <IndustryAnalysisAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields&& tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "irc_app")}/:cntryISO?`}>
                                        <IRCAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "market_actions")}`}>
                                        <MarketActionsAppPage tenant={tenantSettings} token={accessToken} />
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "market_actions")}/markets/:marketID`}>
                                        <MarketActionsMarketPage tenant={tenantSettings} token={accessToken} />
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "pmt_ma")}`}>
                                        <PMTAppPage tenant={tenantSettings} token={accessToken} appToMatch={PMTCONSTANTS.PMT_APPS["pmt_ma"]} />
                                    </Route>
                                    <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "pmt_demand")}`}>
                                        <DemandHealthAppPage tenant={tenantSettings} token={accessToken} appToMatch={PMTCONSTANTS.PMT_APPS["pmt_demand"]} />
                                    </Route>
                                    <Route path={`${path}chinese_market`}>
                                        <ChineseMktAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}addressable_pop`}>
                                        <AdrsPopEstAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}campaign_hub`}>
                                        <CampaignHubAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}ethiopia_country_app`}>
                                        <EthiopiaCtryAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}nigeria_country_app`}>
                                        <NigeriaCtryAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}meta`}>
                                        <MetaAppPage tenant={tenantSettings} />
                                    </Route>
                                    <Route exact path={`${path}vaccine_markets`}>
                                        <VaccineMarketsListPage tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}vaccine_markets/:diseaseSlug`}>
                                        <VaccineMarketsAppPage tenant={tenantSettings} token={accessToken} />
                                    </Route>
                                    <Route exact path={`${path}intelligence`}>
                                        <IntelligenceAppPage tenant={tenantSettings} token={accessToken} />
                                    </Route>
                                    <Route path={`${path}intelligence/reports/:reportID`}>
                                        <IntelligenceDisplay tenant={tenantSettings} />
                                    </Route>
                                    <Route path={`${path}intelligence/create`}>
                                        <IntellEditReport type={'create'} tenant={tenantSettings} tooltip={getTooltipInfo(tooltip)} />
                                    </Route>
                                    <Route path={`${path}intelligence/edit/:reportID`}>
                                        <IntellEditReport type={'edit'} tenant={tenantSettings} tooltip={getTooltipInfo(tooltip)} />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                        :
                        apiError ?
                            apiError === '503-Service Unavailable' ?
                                <div className="text-center">
                                    <h1>Setting Up Your Account</h1>
                                    <p>Please wait 30 seconds to a minute and refresh your browser.</p>
                                </div>

                                : <div className='signin-landing-page'>
                                    <Card className='welcome-back'>
                                        <p>{apiError}</p>
                                        <h5>Something's missing!</h5>
                                        <Button variant='primary' onClick={() => history.goBack()}>Go Back</Button>
                                    </Card>
                                </div> :
                            <div className='signin-landing-page'>
                                <Card className='welcome-back'>
                                    <h1>Welcome Back</h1>
                                    <p>Please sign in to view the application</p>
                                    <SigninLink>
                                        <Button style={{ margin: 'auto', cursor: 'pointer' }}>
                                            <Image
                                                src={microsoftLogo}
                                                alt='Microsoft Logo'
                                                style={{
                                                    width: "auto",
                                                    height: "1.25em"
                                                }}
                                            />
                                            Sign in with Microsoft
                                        </Button>
                                    </SigninLink>
                                </Card>
                            </div>
                }

            </Row>
        </ToastProvider>
    </>
  )
}

export default SignInPageRoutes