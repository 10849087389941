import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'

import StatefulSearchTable from '../StatefulSearchTable';
import AppEditor from './AppEditor'
import {useToast} from '../../ToastProvider'
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';

const metaData=[
  {title:'App Type', dispField:'appType', filterField:'appType'}
]

const sortData=[
  {title:"App Type", field:'appType'}
]

async function fetchData(setApps, apiKey, addToast) {
  setApps([])
  let result = await utils.getData("get_apps", apiKey)
  if (typeof result === 'string'){
    addToast({
      title: 'Failed to load data. Error code 5-d',
      body: result
    })
  } else {
    setApps(result);
  } 
}

function AppSearch(props) {
  // Local representation with data augmentations
  const [apps, setApps] = useState([])
  // Product that gets edited when double clicked
  const [appId, setAppId] = useState(null)
  // A toggle for showing the edit product modal
  const [showEditApp, setShowEditApp] = useState(false)
  // Automatic trigger to refetch the data whenever the user is done editing the data.
  // This is automatically triggered by switching the t/f variable for showing the editing modal to false
  
  const {addToast} = useToast();
  const [getFromLocalStore, setGetFromLocalStore] = useState(false);

  
  // only fetch data if 'Nav' tab is selected and editor is closed
  useEffect(() => {
    if (props.editor !== 'Nav' || showEditApp) return;
    fetchData(setApps, props.apiKey, addToast);
  }, [props.editor, showEditApp, props.apiKey, addToast]);
  


  function toggleShowEditApp() {
    setShowEditApp(!showEditApp)
  };

  function createNew() {
    toggleShowEditApp()
    setAppId(null)
    setGetFromLocalStore(false);
  }

  function update(app) {
    toggleShowEditApp()
    setAppId(app.app_id)
    setGetFromLocalStore(false);
  }

  function openLocalStoreApp(){
    setShowEditApp(true);
    setAppId(null);
    setGetFromLocalStore(true);
  }

  // Passed into search table to add buttons to the left of the required buttons
  const toolbarButtons = () => {
    return (
      <>
        <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
      </>
    )
  }

  return (
    <div>
      {showEditApp ?
        <AppEditor 
          id={appId}
          handleSubmit={toggleShowEditApp}
          closePopup={toggleShowEditApp}
          apiKey={props.apiKey}
          appClass={props.appClass}
          getFromLocalStore={getFromLocalStore}
          toastClick={openLocalStoreApp}
        />
        : null
      }
      <StatefulSearchTable
        data={apps}
        metaData={metaData}
        sortData={sortData}
        onRowClick={update}
        apiKey={props.apiKey}
        editor={props.editor}
        module='app'
        rowsPerPage={20}
        toolbarButtons={toolbarButtons}
        appClass={props.appClass}
      />
    </div>
  )
}

export default AppSearch;