const CONSTANTS = {

    SERVERS: {
        DEV: 'https://lb-dev-web-api-2.azurewebsites.net',
        PROD: 'https://lb-prod-web-api-2.azurewebsites.net'
    },

    LB_WKSPC_ID: 'ae538dba-b0f9-4298-8476-734c6b608c5d',
    GHMH_WKSPC_ID: '79da3464-4fe1-4010-988c-521f02ac859a',

    INTELLIGENCE_FILE_TYPES : '.png, .jpg, .jpeg, .gif',

    INTELLIGENCE_REF_TYPES : '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv',

    FILTER_BAR_API_NAMES: {
        AGILITY: 'agility',
        INTELLIGENCE: 'intel-filter'
    },

    ENTITY_TYPES: {
        COMPANY_PROFILE: 'company',
        VACCINE_MARKETS: 'vaccine',
        REG_ENV: 'regulatoryEnv'
    },

    SECTIONS: {
        COMPANY_PROFILE: 'companyProfiles',
        VACCINE_MARKETS: 'vaccineMarkets',
        REG_ENV: 'RegulatoryEnvs'
    },

    URL_ENTITIES: {
        COMPANY_PROFILE: 'companies',
        VACCINE_MARKETS: 'vaccine-markets',
        REG_ENV: 'regulatory'
    },

    DEFAULT_FIELD: {
        COMPANY_PROFILE: 'introduction',
        VACCINE_MARKETS: 'overview',
        REG_ENV: 'overview'
    },

    APPS_BY_APPTYPE: {
        NEWS: 'news',
        SEARCH: 'search',
        COMPANY_PROFILES: 'company_profiles',
        MY_COMPANY_PROFILE: 'my_company_profile',
        PARTNER_PROFILES: 'partner_profiles',
        INDUSTRY_ANALYSIS: 'industry_analysis',
        VACCINE_MARKETS: 'vaccine_markets',
        VACCINE_ALMANAC: 'vaccine_almanac',
        ADDRESSABLE_POP_EST: 'addressable_pop',
        MARKET_WATCH: 'market_watch',
        INTELLIGENCE: 'intelligence',
        MARKET_ACTIONS: 'market_actions',
        ROADMAP_ACTIONS: 'roadmap_actions',
        PMT_MA: "pmt_ma",
        MARKET_ANALYSIS: 'market_analysis',
        GLOBAL_HEALTH_ALCHEMY: 'glbl_hlth_alchemy',
        USER_MANAGEMENT: 'user_management',
        META: 'meta',
        IRC: 'irc_app',
        CE_NEWS: 'ce_news',
        PMT_DEMAND: 'pmt_demand',
        CAMPAIGN_HUB: 'campaign_hub',
        CHINESE_MARKET: 'chinese_market',
        ETHIOPIA_COUNTRY_APP: 'ethiopia_country_app',
        NIGERIA_COUNTRY_APP: 'nigeria_country_app',
    },

    APPS_BY_NAME: {
        NEWS: 'News',
        'NEWS-BY-TAG': 'News By Tag',
        SEARCH: 'Search',
        SETTINGS: 'Settings Panel',
        COUNTRY_PROFILES: 'Country Profiles',
        COMPANY_PROFILES: 'Company Profiles',
        PARTNER_PROFILES: 'Partner Profiles',
        INDUSTRY_ANALYSIS: 'Industry Analysis',
        VACCINE_MARKETS: 'Vaccine Markets',
        ADDRESSABLE_POP: 'Addressable Population Estimator',
        MARKET_WATCH: 'Market Watch',
        INTELLIGENCE: 'Intelligence',
        MARKET_ACTIONS: 'Market Actions',
        ROADMAP_ACTIONS: 'Roadmap Actions',
        PMT_MA: 'PMT Market Actions',
        MARKET_ANALYSIS: 'Market Analysis',
        GLBL_HLTH_ALCHEMY: 'Global Health Alchemy',
        USER_MANAGEMENT: 'User Management',
        TOPIC: 'Topic Node',
        META: 'META',
        IRC_APP: 'Gavi IRC',
        CE_NEWS: 'Campaign News & Publications',
        DEMAND_HEALTH: 'Demand Health', // tab
        PMT_DEMAND: 'Demand Health', // sidebar & appType in Agility
        CAMPAIGN_HUB: 'Campaign Hub',
        CHINESE_MARKET: 'Chinese Market'
    },

    APPNAMES_BY_PATH: {
        news: 'News',
        search: 'Search',
        company_profiles: 'Company Profiles',
        my_company_profile: 'My Company Profiles',
        industry_analysis: 'Industry Analysis',
        vaccine_markets: 'Vaccine Markets',
        vaccine_almanac: 'Vaccine Almanac',
        addressable_pop: 'Addressable Population Estimator',
        market_watch: 'Market Watch',
        market_actions: 'Market Actions',
        roadmap_actions: 'Roadmap Actions',
        pmt_ma: 'PMT Market Actions', 
        intelligence: 'Intelligence',
        market_analysis: 'Market Analysis',
        glbl_hlth_alchemy: 'Global Health Alchemy',
        partner_profiles: 'Global Health Partner Profiles',
        user_management: 'Manage Users For Workspace',
        meta: 'Meta',
        irc_app: 'Gavi IRC',
        ce_news: 'Campaign News & Publications',
        demand_health: 'Demand Health',
        campaign_hub: 'Campaign Hub',
        chinese_market: 'Chinese Market',
        nigeria_country_app: 'Nigeria Country App',
        ethiopia_country_app: 'Ethiopia Country App'
    },

    LIST_BY_APPNAME: {
        NEWS: 'Articles',
        COMPANY_PROFILE: 'companyprofiles',
        ADDRESSABLE_POP: 'addressablepopulationestimator',
        INDUSTRY_ANALYSIS: '',
        VACCINE_MARKETS: 'markets',
        MARKET_WATCH: '',
        MARKET_ACTIONS: '',
        PMT_MA: '',
        INTELLIGENCE: 'cx',
        MARKET_ANALYSIS: 'markets',
        GLOBAL_HEALTH_ALCHEMY: '',
        PARTNER_PROFILES: 'ghafpartnerprofiles',
        META: 'meta',
        IRC_APP: 'gaviircapp',
        CAMPAIGN_HUB: 'campaignhub',
        CHINESE_MARKET: 'markets'
    },

    APPS_FOR_TAB_SEARCH: {
        NEWS: "News",
        INTELLIGENCE: "Intelligence"
    },

    FUNCTIONAL_INFO_OWNER: {
        BMGF: '1',
        CHAI: '2',
        Gavi: '3',
        PATH: '4',
        UNICEF: '5',
        WHO: '6',
        OTHER: '7',
        'Square-Group': '8',
        CEPI: '9'
    },

    EYES_LIST: {
        BMGF: '1',
        CHAI: '2',
        Gavi: '3',
        UNICEF: '5',
        WHO: '6',
        CEPI: '9'
    },

    TENANT_SLUG_TO_EYES: {
        BMGF: 'bmgfEyes',
        CHAI: 'chaiEyes',
        'Gavi-MST': 'gaviEyes',
        UNICEF: 'unicefEyes',
        WHO: 'whoEyes',
        CEPI: 'cepiEyes'
    },

    //basically, some tenants need more than one eyes checked so let's track it here for now
    EYES_FOR_TENANT_VIEW: {
        BMGF: ['bmgfEyes'],
        CHAI: ['chaiEyes'],
        'Gavi-MST': ['gaviEyes'],
        UNICEF: ['unicefEyes'],
        WHO: ['whoEyes'],
        OTHER: ['otherEyes'],
        'Square-Group': ['bmgfEyes','gaviEyes','whoEyes','unicefEyes'],
        CEPI: ['cepiEyes']
    },

    MARKET_ACTIONS_VIEWS: {
        TABLE: '1',
        KANBAN: '2',
        GANTT: '3'
    },
    MARKET_ACTIONS_REVERSE_VIEWS: {
        '1': 'table',
        '2': 'kanban',
        '3': 'gantt'
    },

    MARKET_ACTIONS_HEADERS: [
        {
            TASK_NAME: 'Task',
            VACCINE: 'Vaccine',
            VACCINE_ROADMAP: 'Vaccine RoadMap',
            HMF_CATEGORY: 'HMF Category',
            OBJECTIVE: 'Objective',
            TARGET_OUTCOME: 'Target Outcome',
            ASSIGNED_TO: 'Assigned To',
            STAKEHOLDER_ACCOUNTABLE: 'Stakeholder Accountable',
            STAKEHOLDER_SUPPORTING: 'StakeHolder Supporting',
            DUE_DATE: 'Due Date',
            PRIORITY: 'Priority',
            STATUS: 'Status',
            PRIORITIZED_INNOVATION: 'Prioritized Innovation'
        }
    ],

    SETTINGS: {
        SEC_LEVELS: [
            {label: '1', value: '1'},
            {label: '2', value: '2'},
            {label: '3', value: '3'},
            {label: '4', value: '4'},
            {label: '5', value: '5'},
            {label: 'Q', value: '6'},
        ]
    },

    ELASTIC_ENGINES: {
        PUB_AND_GHAF: 'linksbridge',
        PUBLIC : 'linksbridge',
        GHAF: 'ghaf-articles',
        CX: 'cxreports',
        CE: 'cearticles'
    },

    ELASTIC_PAGE_TYPES: {
        SEARCH: 'search',
        INTELLIGENCE: 'intelligence',
        NEWS: 'news'
    },

    ELASTIC_ENGINES_BY_APP: {
        'intelligence': 'cxreports',
        'news': 'linksbridge',
        'ce_news': 'ce-articles',
        'irc_app': 'ce-articles'
    },
    
    MULTI_SELECT_COLORS: [
        'surface-accent-teal',
        'surface-accent-lgt-purple',
        'surface-accent-peach'
    ],
    
    FILTER_TYPES: {
        SORT_BY_RECENT: 'SORT_BY_RECENT',
        DATE_FILTER: 'DATE_FILTER',
        COUNTRY_FILTER: 'COUNTRY_FILTER',
        NAME_FILTER: 'NAME_FILTER',
        DATE_RANGE_FILTER: 'DATE_RANGE_FILTER',
        OWNER_FILTER: 'OWNER_FILTER',
        SENSITIVITY_FILTER: 'SENSITIVITY_FILTER',
        SOURCE_TYPE_FILTER: 'SOURCE_TYPE_FILTER',
        COMPANY_TYPE_FILTER: 'COMPANY_TYPE_FILTER',
        SORT_BY_RELEVANCE: 'SORT_BY_RELEVANCE',
        CATEGORY: 'CATEGORY'
    },

    FILTER_TITLES: {
        SORT_BY_RECENT: 'Sort By',
        SORT_BY_RELEVANCE: 'Relevance',
        DATE_FILTER: 'Date Range',
        COUNTRY_FILTER: 'Country',
        NAME_FILTER: 'Search by Name',
        DATE_RANGE_FILTER: '',
        OWNER_FILTER: 'Information Owner',
        SENSITIVITY_FILTER: 'Sensitivity',
        SOURCE_TYPE_FILTER: 'Source Type',
        COMPANY_TYPE_FILTER: 'Company Type',
        CATEGORY: 'Category'
    },

    SUPPORT_FORM: {
        CATEGORIES: [
            {value: 'General Comment', label: 'General Comment'},
            {value: 'Qualitative Data Correction', label: 'Qualitative Data Correction'},
        ]
    },
    FILTER_OPTIONS: {
        OWNER_FILTER: { 1:'BMGF', 2:'CHAI', 3:'Gavi', 4:'PATH', 5:'UNICEF', 6:'WHO', 7:'OTHER', 8: 'Square-Group', 9: 'CEPI'},
        SENSITIVITY_FILTER: {1:'Public', 2:'Privileged', 3:'Confidential'}
    },

    FILTER_LISTS: {
        SORT_BY_RECENT: [
            {
                label: 'Most Recent',
                value: 'DATE_DESC'
            },
            {
                label: 'Oldest',
                value: 'DATE_ASC'
            }
        ],
        SORT_BY_RELEVANCE: [
            {
                label: 'Relevance',
                value: 'SCORE'
            },
            {
                label: 'Most Recent',
                value: 'DATE_DESC'
            },
            {
                label: 'Oldest',
                value: 'DATE_ASC'
            }
        ],
        COMPANY_TYPE_FILTER: [
            {
                label: 'DCVM',
                value: 'DCVM'
            },
            {
                label: 'MNC',
                value: 'MNC'
            },
            {
                label: 'Biotech',
                value: 'Biotech'
            },
            {
                label: 'Academic',
                value: 'Academic'
            },
            {
                label: 'Other',
                value: 'Other'
            },
            {
                label: 'Government',
                value: 'Government'
            }
        ],
        DATE_FILTER: [
            {
                label: '--',
                value: ''
            },
            {
                label: 'Today',
                value: 'today'
            },
            {
                label: 'This Week',
                value: 'this-week'
            },
            {
                label: 'This Month',
                value: 'this-month'
            }
        ],
        OWNER_FILTER: [
            {
                label: 'BMGF',
                value: '1'
            },
            {
                label: 'CHAI',
                value: '2'
            },
            {
                label: 'Gavi',
                value: '3'
            },
            {
                label: 'PATH',
                value: '4'
            },
            {
                label: 'UNICEF',
                value: '5'
            },
            {
                label: 'WHO',
                value: '6'
            },
            {
                label: 'OTHER',
                value: '7'
            },
            {
                label: 'Square-Group',
                value: '8'
            },
            {
                label: 'CEPI',
                value: '9'
            }
        ],
        SENSITIVITY_FILTER: [
            {
                label: 'Public',
                value: '1'
            },
            {
                label: 'Privileged',
                value: '2'
            },
            {
                label: 'Confidential',
                value: '3'
            }
        ],
        SOURCE_TYPE_FILTER: [
            {
                value: 'Frml Spplr Mtg Call', 
                label: 'Formal supplier meeting/call'
            },
            {
                value: 'Roadshow/spplr cnslts', 
                label: 'Roadshow/supplier consultations'
            },
            {
                value: 'Public meeting', 
                label: 'Public meeting'
            },
            {
                value: 'Private mention', 
                label: 'Private mention'
            },
            {
                value: 'Counterpart intel', 
                label: 'Counterpart intel'
            },
            {
                value: 'Other', 
                label: 'Other'
            }
        ],
        CATEGORY : [
            {
                value: 'all-articles',
                label: 'All'
            },
            {
                value: 'ghaf-articles',
                label: 'GHAF News'
            },
            {
                value: 'linksbridge',
                label: 'Linksbridge News'
            }
        ]
    },
    DATE_RANGES: {
        TODAY: '24',
        THIS_WEEK: '168',
        THIS_MONTH: '730'
    },

    NUMBER_TO_MONTHNAMES: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    
    SORT_BY_ITEMS: {
        RELEVANCE: 'relevance',
        MOST_RECENT: 'DESC',
        OLDEST: 'ASC'
    },

    //industry analysis
    COMPANY_CAPABILITY_ANALYSES_TABS: [
        { 
            title: 'Business Sustainability', 
            fields: [
                { fieldName: 'pyramidBusinessSustainability', type: 'PYRAMID' },
                { fieldName: 'businessSustainability', type: 'RTF' }
                
            ]
        },
        { 
            title: 'R&D and Regulatory', 
            fields: [
                { fieldName: 'pyramidRDandRegulatory', type: 'PYRAMID' },
                { fieldName: 'rDandRegulatory', type: 'RTF' }
                
            ]
        },
        { 
            title: 'Production and Supply', 
            fields: [
                { fieldName: 'pyramidProductionandSupply', type: 'PYRAMID' },
                { fieldName: 'productionandSupply', type: 'RTF' }
                
            ]
        }
    ],


    // NEW CX REPORT DROPDOWN SELECTS

    SOURCE_TYPE: [
        {value: '-', label: '-'},
        {value: 'Frml Spplr Mtg Call', label: 'Formal supplier meeting/call'},
        {value: 'Roadshow/spplr cnslts', label: 'Roadshow/supplier consultations'},
        {value: 'Public meeting', label: 'Public meeting'},
        {value: 'Private mention', label: 'Private mention'},
        {value: 'Counterpart intel', label: 'Counterpart intel'},
        {value: 'Other', label: 'Other'}
    ],

    SENSITIVITY: [
        {value: '--', label: '-'},
        {value: '1', label: 'Public'},
        {value: '2', label: 'Privileged'},
        {value: '3', label: 'Confidential'},
    ],

    RELIABILITY: [
        {value: '--', label: '-'},
        {value: 'A', label: 'Very high'},
        {value: 'B', label: 'High'},
        {value: 'C', label: 'Medium'},
        {value: 'D', label: 'Low'},
        {value: 'E', label: 'Very low'},
        {value: 'F', label: 'Cannot be determined'}
    ],

    CREDIBILITY: [
        {value: '-', label: '-'},
        {value: '1', label: 'Confirmed by other sources'},
        {value: '2', label: 'Probably true'},
        {value: '3', label: 'Possibly true'},
        {value: '4', label: 'Doubtful'},
        {value: '5', label: 'Improbable'},
        {value: '6', label: 'Truth cannot be judged'}
    ],

    USERS_ACTIONS: {
        DEFAULT_WORKSPACE_CHANGE: 'Default Workspace',
        SECURITY_LEVEL: 'Security Level',
        ADD_TO_WORKSPACE: 'Add to Workspace'
    },
//Status constants
    MARKET_ACTIONS_STATUS: {
        0:'Not started',
        1:'In progress',
        2:'Delayed',
        3:'Deprioritized',
        4:'Complete'
    },

    MARKET_ACTIONS_STATUS_SELECT: [
        {value: 0, label: 'Not started'},
        {value: 1, label: 'In progress'},
        {value: 2, label: 'Delayed'},
        {value: 3, label: 'Deprioritized'},
        {value: 4, label: 'Complete'}
    ],

    
    // Priority Constants
    
        MARKET_ACTIONS_PRIORITY: {
            High: 0,
            Medium: 1,
            Low: 2
        },
    
        MARKET_ACTIONS_PRIORITY_VAL_OBJ: {
            0: 'High',
            1: 'Medium',
            2: 'Low'
        },
    
        MARKET_ACTIONS_PRIORITY_UPPERCASE: {
            HIGH: 0,
            MEDIUM: 1,
            LOW: 2
        },

        
    MARKET_ACTIONS_REVERSE_PRIORITY: {
        0: "high",
        1: "medium",
        2: "low"
    },

    MARKET_ACTIONS_PRIORITY_ARRAY: [
        "High",
        "Medium",
        "Low"
    ],

    MARKET_ACTIONS_PRIORITY_SELECT: [
        {value: '', label: ''},
        {value: 0, label: 'High'},
        {value: 1, label: 'Medium'},
        {value: 2, label: 'Low'}
    ],
// Accuracy Constants
    MARKET_ACTIONS_ACCURACY_SELECT: [
        {value: '', label: ''},
        {value: 0, label: 'Low'},
        {value: 1, label: 'Average'},
        {value: 2, label: 'Exact'}
    ],

    MARKET_ACTIONS_ACCURACY_ARRAY: [
        'Low',
        'Average',
        'Exact'
    ],

    MARKET_ACTIONS_ACCURACY_VAL_OBJ: {
        Low: 0,
        Average: 1,
        Exact: 2
    },

    MARKET_ACTIONS_ACCURACY_LABEL_OBJ: {
        0:'Low',
        1:'Average',
        2:'Exact'
    },

//Prioritized Innovation Constants
    MARKET_ACTIONS_PRIORITIZED_INNOVATION_SELECT: [
        {label: "Prioritised for interventions", value: 0},
        {label: "Prioritised for monitoring", value: 1},
        {label: "Deprioritised", value: 2},
        {label: "NA", value: 3}
    ],

    
    MARKET_ACTIONS_REVERSE_PRIOR_INNOVATION: {
        0: 'Prioritised for interventions',
        1: 'Prioritised for monitoring',
        2: 'Deprioritized',
        3: 'NA'
    },

    MARKET_ACTIONS_PRIOR_INNOVATION: {
        'Prioritised for interventions': 0,
        'Prioritised for monitoring': 1,
        'Deprioritized': 2,
        'NA': 3
    },

    MARKET_ACTIONS_PRIOR_INNOVATION_ARRAY: [
        'Prioritised for interventions',
        'Prioritised for monitoring',
        'Deprioritized',
        'NA'
    ],

//HMF Category Constants
    MARKET_ACTIONS_HMF_CATEGORY_SELECT: [
        {value: "d9ff9b7b-ec39-49c0-8fe1-5fa26bd6939a", label: "Demand"},
        {value: "8ecdc44e-4b00-4a2a-9afe-6253f4a7ac10", label: "Supply"},
        {value: "041f197e-dfeb-43c8-9efd-16d5c3768e79", label: "Innovation"}
    ],

    MARKET_ACTIONS_HMF_CATEGORY_ARRAY: [
        'Demand',
        'Supply',
        'Innovation'
    ],
    
    MARKET_ACTIONS_REVERSE_HMF_CATEGORY: {
        'd9ff9b7b-ec39-49c0-8fe1-5fa26bd6939a': "Demand",
        '8ecdc44e-4b00-4a2a-9afe-6253f4a7ac10': "Supply",
        '041f197e-dfeb-43c8-9efd-16d5c3768e79': "Innovation"
    },


    MARKET_ACTIONS_HMF_CATEGORY_VALUE_OBJ: {
        "Demand": "d9ff9b7b-ec39-49c0-8fe1-5fa26bd6939a",
        "Supply": "8ecdc44e-4b00-4a2a-9afe-6253f4a7ac10",
        "Innovation": "041f197e-dfeb-43c8-9efd-16d5c3768e79" 
    },

    //Due Date Type Constants
    MARKET_ACTIONS_DUE_DATE_TYPE_SELECT: [
        {value: 0, label: 'Ongoing'},
        {value: 1, label: 'Annual'},
        {value: 2, label: 'N/A'},
        {value: 3, label: 'Fixed date'}
    ],
    
    MARKET_ACTIONS_DUE_DATE_TYPE_VAL_OBJ: {
        'Ongoing': 0,
        'Annual': 1,
        'N/A': 2,
        'Fixed date': 3
    },

    MARKET_ACTIONS_DUE_DATE_TYPE: {
        0:'Ongoing',
        1:'Annual',
        2: 'N/A',
        3: 'Fixed date'
    },

    MARKET_ACTIONS_GROUP_BY: {
        'priorInnovation': 'prior_innovation',
        'status': 'status',
        'priority': 'priority',
        'supplier': 'supplier'
    },

    MARKET_ACTIONS_VIEW_OPTIONS: {
        SEARCH: {
            label: 'Search For Markets',
            value: 'markets-search'
            },
        FILTER: {
            type: 'popup',
            label: 'Filter By',
            value: 'filter'
            },
        PAGER: {
            type: 'pager',
            label: 'Pager',
            value: 'pager'
            },
        GROUPBY: {
            type: 'dropdown',
            label: 'Group By',
            value: 'group'
            },
        ASSIGNEES: {
            type: 'dropdown',
            label: 'Assignees',
            value: 'assignees'
            },
        COLUMNS: {
            type: 'popup',
            label: 'Columns',
            value: 'cols'
            },
        EDIT: {
            type: 'linkToEditView',
            label: 'Edit',
            value: 'edit'
            },
        DELETE: {
            type: 'popup',
            label: 'Delete',
            value: 'delete'
            },
        NOTIFICATIONS: {
            type: '',
            label: 'Notifications',
            value: 'notifications'
            },
        MORE_OPTIONS: {
            type: 'dropdown',
            label: 'More Options',
            value: 'moreOpts'
        }
    },

    MARKET_ACTIONS_FIELD_TYPES: {
        DATE_TYPE: 'DATE',
        NUMERICAL_TYPE: 'NUMERICAL',
        TEXT_TYPE: 'TEXT',
        ASSIGNEE_TYPE: 'ASSIGNEES',
        STATUS_TYPE: 'STATUS',
        BOOLEAN_TYPE: 'BOOLEAN',
        LABEL_LIST_TYPE: 'LABELLIST'
    },

    MARKET_ACTIONS_KANBAN_CUSTOM_TO_FLAT: {
        'Assigned To': 'assigned_to',
        'HMF Category': 'hmf_category',
        'Supplier': 'supplier',
        'Priority': 'priority',
        'Stakeholder Accountable': 'stakeholder_accountable',
        'Stakeholder Supporting': 'stakeholder_supporting',
        'Created By': 'created_by',
        'Due Date Type': 'due_date_type',
        // 'Prioritized Innovation (Yes/No)': 'prioritized_innovation',
        'Prioritized Innovation': 'prior_innovation',
        'Target Outcome': 'target_outcome',
        'Vaccine Roadmap': 'vaccine_roadmap',
        'Vaccine': 'vaccine'
    },

    MARKET_ACTIONS_ADMIN_MKT_NAME: 'Test Market',

    DB_HEADERS: {
        'Content-Type': 'application/json',
        'access_token': process.env.REACT_APP_DB_API_KEY
    },

    SHELL_VACCINE: {
        vaccine_id: null,
        vaccine_name: '',
        vaccine_subtype: '',
        deleted:false,
        antigens: [],
        alt_names:[],
        gavi_visible:false
    },

    SHELL_DRUG: {
        drug_id: null,
        drug_name: '',
        generic_name: '',
        global_data_drug_id: '',
        molecule_type: '',
        route_of_administration: '',
        mechanism_of_action: '',
        indication_phases: []
    },

    SHELL_PRODUCT: {
        product_id: null, 
        manufacturer_id: null,
        lb_status: null,
        product_name: null, 
        alt_names: [],
        strains:'',
        platforms:[],
        product_class:'vaccine',
        manufacturer: null,
        route_of_administration: null,
        min_doses_per_regimen: null,
        max_doses_per_regimen: null,
        min_age: null,
        min_age_unit: null,
        max_age: null,
        max_age_unit: null,
        vaccine_id: null,
        organizations: [],
        vaccine: null,
        form:null,
        shelf_life:'',
        development_phase:null,
        characteristics:[],
        capacity_details:[],
        capacity_probabilities:[],
        source:null,
        update_notes:null,
        drug_substance:[]
    },

    SHELL_ORGANIZATION: {
        org_id: null,
        org_name: null,
        org_country: null,
        pq_experience: false,
        gavi_visible: false,
        deleted:false,
        licensure_experience: false,
        country: null,
        alt_names:[],
        production_locations:[],
        parent:null
    },

    SHELL_PRODUCTION_LOCATION : {
        production_location_id: null,
        org_id: null,
        location_name: null,
        country_id: null,
        city_id: null,
        country: [],
        city: []
    },

    SHELL_CITY: {
        ISO: null,
        admin_level: null,
        country: [],
        geography_id: null,
        geography_name: null,
        geography_type: null,
        city_id: null
    },

    SHELL_COUNTRY: {
        ISO: null,
        UNICEF_region: null,
        WHO_region: null,
        alt_names: [],
        country: null,
        releasing_nra: null
    },

    SHELL_COUNTRY_INFO: {
        ISO: null,
        year: null,
        wb_status: null,
        gni_per_capita: null,
        gdp_per_capita:null,
        gavi_cofinancing: null,
        gavi_malaria_cofinancing: null,
        gavi_eligibility: null,
        covax_status: null,
        ida_status: null,
        revolving_fund: false,
        health_system_strength: null,
        health_spending_as_percent_of_gdp: null
    },

    SHELL_ENUM: {
        enum: null,
        enum_type: null,
        alt_enums: [],
        deleted: false
    },

    SHELL_PROGRAM: {
        schedule_rounds: [],
        geography: null,
        routine:true,
        standard:true,
        gender:"Both",
        geography_id: null,
        deleted:false
    },

    COUNTRY_ADAPTER: {
        toJvector : {
            'DRC': 'Congo, Democratic Republic of the',
            'CAR': 'Central African Republic',
            "Cote d'Ivoire": "Côte d'Ivoire",
            "Laos": "Lao People's Democratic Republic",
            "North Korea": "Korea, Democratic People's Republic of",
            "Sao Tome": "Sao Tome and Principe",
            "Syria": "Syrian Arab Republic",
            "Tanzania": "Tanzania, United Republic of"
        },
        toCE: {
            'Congo, Democratic Republic of the': 'DRC',
            'Central African Republic': 'CAR',
            "Côte d'Ivoire": "Cote d'Ivoire",
            "Lao People's Democratic Republic": "Laos",
            "Korea, Democratic People's Republic of": "North Korea",
            "Sao Tome and Principe": "Sao Tome",
            "Syrian Arab Republic": "Syria",
            "Tanzania, United Republic of": "Tanzania"
        }
    },
    NEW_PBI_APPS: ['companyprofiles', 'markets', 'industry analysis', 'chinese market']
}

export default CONSTANTS