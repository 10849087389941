import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import SimpleSelect from '../SimpleSelect';
import '../../../styles/layout/_layout.scss'

const operatorOptions = ['In', 'Not in', 'All'];

function SubNavEditor({ pBIFilter, onChange }) {

  function handleChange(value, idx, field) {
    const temp = [...pBIFilter];
    temp[idx][field] = value;
    onChange(temp);
  }

  function addFilter() {
    const temp = [...pBIFilter];
    temp.push({ filterValues: '', operator: '', targetColumn: '', targetTable: '' });
    onChange(temp);
  }

  function deleteFilter(idx) {
    const temp = [...pBIFilter];
    temp.splice(idx, 1);
    onChange(temp);
  }

  return (
    <div className = ''>
      <Row>
        <Col xs={10}/>
        <Col>
          <Button className='button-secondary m-i-end-02' onClick={addFilter} variant="secondary">
            Add Filter
          </Button>
        </Col>
      </Row>
      <table striped size="sm" className="bottom-margin table table-striped search-table">
        <thead className='search-table-thead'>
          <tr className='search-table-tr'>
            <th className='search-table-th'>Filter Values</th>
            <th className='search-table-th'>Operator</th>
            <th className='search-table-th'>Target Column</th>
            <th className='search-table-th'>Target Table</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="search-table-tbody">
          {
            pBIFilter &&
            pBIFilter.map((pbif, index) => {
              return <tr key={index}>
                <td>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={pbif.filterValues}
                    onChange={(e) => handleChange(e.target.value, index, 'filterValues')} />
                </td>
                <td>
                  <SimpleSelect
                    className = 'select-lg'
                    menuPlacement='top'
                    options={operatorOptions}
                    value={pbif.operator}
                    onChange={(e) => handleChange(e, index, 'operator')}
                  />
                </td>
                <td>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={pbif.targetColumn}
                    onChange={(e) => handleChange(e.target.value, index, 'targetColumn')} />
                </td>
                <td>
                  <input
                    className='form-ctrl'
                    type='text'
                    required
                    value={pbif.targetTable}
                    onChange={(e) => handleChange(e.target.value, index, 'targetTable')}
                  />
                </td>
                <td className="search-table-td">
                  <button className="btn-sm-invisible-red" onClick={() => deleteFilter(index)}>Delete</button>
                </td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>

  )
}

export default SubNavEditor;