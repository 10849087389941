import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import CONSTANTS from '../../constants';
import utils from '../../utils';
import NavUtils from '../../NavUtils';
import UrlDrivenAppDisplay from '../../components/UrlDrivenAppDisplay';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';


function EthiopiaCtryAppPage({ tenant }) {
   
    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.ETHIOPIA_COUNTRY_APP);
  
    if(!hasAccess) {
        history.push('/');
    }
  
    let [ contentModel, setContentModel ] = useState();
    let [ navDescr, setNavDescr ] = useState();
    let [ loading, setLoading ] = useState(true);
    const { instance, accounts } = useMsal();
  
    let tenantSlug = tenant.tenant.fields.tenantslug;
  
    let tenID = utils.getForceTenantValue();
  
    if (!tenID) {
      //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
      tenID = tenant.tenant.fields.aDGroupID || null;
      utils.setForceTenantValue(tenID);
    }
  
    const request = {
      scopes: ["User.Read"]
    };
  
    const trackCall = async (accToken, appType) => {
      //no await on purpose, we don't need to ensure that it returns - just issue it
      utils.trackAppView(appType, tenID, accToken);
    }
  
    const getContentModel = async (token) => {
      let extraParams = 'force_id=' + tenant.tenant.fields.aDGroupID;
  
  
        let jsonresponse = await utils.getTenantContent(token, CONSTANTS.APPS_BY_APPTYPE.ETHIOPIA_COUNTRY_APP, tenantSlug, tenant.ourUserID, extraParams);
  
        if( jsonresponse.appFields){
            setNavDescr(jsonresponse.appFields);
        }
        setContentModel(jsonresponse.content);
  
        setLoading(false);
    }
  
    useEffect( () => {
        try{
            (async () => {
                let accToken = await utils.getAccessToken(instance, accounts[0], request);
                // only track if on prod
                if(process.env.REACT_APP_ENV === 'PROD' ) {
                  trackCall(accToken, 'ethiopia_country_app');
                }
                await getContentModel(accToken);
            })()
        } catch(error) {
            console.log(error);
            setLoading(false);
        }
    // eslint-disable-next-line
    },[]);
  
    return (
      !loading ?
        contentModel ?
          <div className='url-driven-app'>
            <UrlDrivenAppDisplay 
              tenant={tenant}
              content={contentModel[0]}
              navs={navDescr}
              appType={'ETHIOPIA_COUNTRY_APP'}
              defaultPage="overview"  
              displayFieldName={""} 
              hasSubItems={null} />
          </div>
        : <div>Content Model Not Found</div>
      : <ListSkeletonLoader preview={false} />
    )
}

export default EthiopiaCtryAppPage